.changeAssociationButton
{
    font-size: 0.8rem;
    color: #7a54d3;
    text-decoration: underline;
}
.edit-contact-box{
    /* width: 28% !important; */
    padding: .5rem;
}
/* @media only screen and (max-width: 1200px) {
    .edit-contact-box{
        width: 99% !important;
        height: 99% !important; 
        border-radius: none !important;
    }
}  */
@media only screen and (min-width: 768px) and (max-width: 1200px){
    .ipad-top-margin{ 
        margin-top:var(--marginTop);
        height: 100%;
    }
    .edit-contact-box{
        /* width: 28% !important; */
        padding: 0px;
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .ipad-top-margin{ 
        margin-top:var(--marginTop);
        height: 100%;
    }
    .edit-contact-box{
        /* width: 28% !important; */
        padding: 0px;
    }
}
@media only screen  and (max-width: 768px){
    .ipad-top-margin{ 
        /* margin-top:var(--marginTop); */
        /* height: 100%; */
    }
    .edit-contact-box{
        /* width: 28% !important; */
        padding: 0px;
    }
}