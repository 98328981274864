.msg{
    border-radius: 5px;
    width: fit-content;
    max-width: 40rem;
    min-width:100px;
    color:var(--secondary-text-color);
    font-size: 16px;
    font-family: "SF Pro", sans-serif;
    margin-bottom: 3px;
    /* padding: 12px 12px 0rem 12px; */
    border : 1px solid transparent;
}
.sender .msg{
    margin-left: auto;
    background: #e2daf5;
}
.received .msg {
    margin-right: auto;
    background: white;
}
.msg-row{
    display: flex;
    padding: 24px 24px 8px 24px;
    
   
}
.msg-row.sender {
    justify-content: right;
}

.msg-row.received {
    justify-content: left;
}
.msgtext {
    padding-top: 10px;
    /* width: 80%; */
   

}

.sender .msgtext{
    /* sender */
    padding-right: 15px;
}
.received .msgtext {
    /* sender */
    padding-left: 15px;
}
.msg-content{
    font-size: 1rem;
    line-height: 1.5rem;
    margin:  10px 10px 0px 10px;
    white-space: pre-wrap;
}
.msg-details{
    font-size: 12.8px;
    align-items: right;
    justify-content: right;
    text-align: right;
    /* margin: 10px; */
   
    
}
.avatar-block {
    border-radius: 50%;
    height: 40px;
    width:40px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: white;
    /* border: 1px solid #734BD14D; */
    display: flex;
    color: var(--primary-text-color);
    box-shadow: 0px 4px 15px #00000029;
}

.dateBanner {
    position: relative;
    margin-bottom: 8px;
}
.dateBanner .h4-date {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem !important;
    width: 10rem;
    text-align: center;
    padding: 0px 4px;
    position: absolute;
    
    /* transform: translateX(-50%) translateY(-50%); */
    background: var(--secondary-color);
    z-index: 10;
    font-weight: bold;
}

/* .attached-media {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    overflow-x: hidden;
    color:#828287;
}

.attached-media .media-tb {
    height: 30px;
    width: 130px;
    background-color: #e5e5e5;
    margin-top: 10px;
    display: flex;
    margin: 5px;
}

.attached-media .media-tb .media-left {
    flex-direction: column;
    width: 20%;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.attached-media .media-tb .media-left i {
    line-height: 15px;
    text-align: center;
    margin-top:50% ;
}

.attached-media .media-tb .media-left p {
    font-size: 7px;
    line-height: 8px;
}

.attached-media .media-tb div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 65%;
    font-size: 10.8px;
    text-align: center;
    line-height: 28px;
    
}

.attached-media .media-tb div:last-child {
    display: flex;
    width: 15%;
    justify-content: center;
    align-items: center;
    
} */
/* .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    height: auto;
    max-height: 90px;
    overflow-y: scroll;
    margin-top: 10px;
} */
/* 
.grid-container::-webkit-scrollbar {
    width: 3px;
}

.grid-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.grid-container::-webkit-scrollbar-thumb {
    background: #888;
}

.grid-container::-webkit-scrollbar-thumb:hover {
    background: #555;
} */




.dateBanner .vis-hidden {
    visibility: hidden;
}
.dateBanner hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.7);
}
.media-files {
    margin: 10px;
}
.media-files img{

    width: 100%;

}
.media-files .med {
    width: 100%;
    height: auto;
    max-width: 400px;
    max-height: 400px;
}
.attachcard{
    text-decoration: none;
}
.attachcard a{
    /* border: 1px solid red; */
    
    color: var(--secondary-text-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2px;
}
.sender .attachcard a{
    background-color: var(--white); 
}
.received .attachcard a{   
    border: 1px solid #E7E7E7;
}
.attachcard a:hover{
    color: #4F2AA5;
}
.attachcard a:hover h5{
    color: #4F2AA5;
}
.attachcard h5{
    /* border: 1px solid blue; */
    /* margin: 0px; */
    color: var(--secondary-text-color);
    text-decoration: none !important;
    margin: 4px;
}
.attachcard i{
    /* border: 1px solid pink; */
    margin: 4px;
}

/* .msg-attachment{
    border: 1px solid blue;
}
.msg-attachment .attachcard{
    border: 1px solid blueviolet;

} */

@media only screen and (max-width:700px) {
    .sender .attachcard a{
        border: 1px solid #E7E7E7;
    }
    .received .attachcard a{
        background-color: var(--white); 
    }
}

/* @media only screen and (min-width: 1200px){
    .media-files .med {
        max-width: 400px;
        max-height: 400px;
    }
} */
